import { NavLink } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import buttonhome from './assets/buttonhome3.png';
import Footer from './footer';
import Events from './events';
import './eventspage.css';

export default function eventspage(){
    return(
        <div>
			<div>
                <div className="homebutton-gantevents"><NavLink to='/'><Image src={buttonhome}></Image></NavLink></div>
                <div className="gnatevents"><h1>Gnat Top 10 Mints</h1></div>
				<div className='gnatevents_gnatsgif' />
            </div>
				<div className="box-main-gnatevents">
					<div> 
						<Events />
					</div>
				</div>	
			<div className='empty-gnatevents' /> 
            <Footer></Footer>
        </div>
    )
}