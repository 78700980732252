import { NavLink } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import buttonhome from './assets/buttonhome3.png';
import './whygnats.css';
import Footer from './footer';

export default function WhyGnats(){
    return(
        <div>
			<div>
                <div className="homebutton-why"><NavLink to='/'><Image src={buttonhome}></Image></NavLink></div>
                <div className="why-gnats"><h1>WHY GNATS?</h1></div>
				<div className='why_gnatsgif' />
            </div>
				<div className="box-main-whygnats">
					<div> 
						<h1 className="subheading-why">
                        The Enigmatic World of Flying Gnats NFT Project: Unveiling a Truly Unique Digital Experience
						</h1>
						<p className="text-small-whygnats">
                            In the vast universe of NFTs (Non-Fungible Tokens), where artistic expression knows no bounds, a remarkable project has taken flight: the Gnats NFT Project. With its captivating blend of art, randomness, and nature-inspired designs, this collection of 10,000 flying Gnats promises to transport collectors into a realm brimming with endless possibilities and visual delight. We will delve into the distinctive features that set this project apart from others in the world of NFTs.
						</p>
                        <h1 className="subheading-why">
                        The Essence of Flight
						</h1>
						<p className="text-small-whygnats">
                        While many NFT projects feature static images, Gnats NFT Project brings an extra dimension to the table. Each Gnat is depicted in mid-flight, as if defying gravity itself. This dynamic element adds a sense of life and motion to the collection, breathing a new sense of vitality into the digital art world.
						</p>
                        <h1 className="subheading-why">
                        A Kaleidoscope of Colors:
						</h1>
						<p className="text-small-whygnats">
                        Prepare to immerse yourself in a vibrant spectrum of hues! Gnats NFT Project distinguishes itself by offering a mesmerizing range of colors while creating Gnats and shapes, ensuring that no two Gnats share the same palette. From dazzling primaries to subtle pastels, the diverse coloration of each Gnat underscores the individuality and uniqueness of every piece.
						</p>
                        <h1 className="subheading-why">
                        An Unpredictable Tapestry:
						</h1>
						<p className="text-small-whygnats">
                        In a world where randomness reigns supreme, Gnats NFT Project embraces chance by introducing unexpected flight paths for each Gnat. No two Gnats follow the same trajectory, enhancing the mystique and surprise factor of this collection. As you explore the Gnats, you will witness their whimsical paths unfold, inviting you to uncover the secrets of their unpredictable journeys.
						</p>
                        <h1 className="subheading-why">
                        Nature-Inspired Shapes:
						</h1>
						<p className="text-small-whygnats">
                        Adding a touch of organic beauty to the collection, Gnats showcase an assortment of natural shapes. With each Gnat possessing either a Circle, Semi-Circle, square, triangle, rectangle, star, or heart shape, collectors are treated to an enchanting symphony of forms that pay homage to the beauty found in the world around us. These shapes provide a visual feast that is sure to captivate and inspire.
						</p>
                        <h1 className="subheading-why">
                        Uniqueness in Motion:
						</h1>
						<p className="text-small-whygnats">
                        In the realm of Gnats NFT Project, every animated GIF is utterly unique. No two Gnats share the same combination of Number of Gnats, color, flight path, and shapes. All Gnats has specific Total flight path calculated in "Pixels". This commitment to individuality ensures that each NFT represents a one-of-a-kind masterpiece, elevating the collection's value and desirability among discerning collectors.
						</p>
                        <h1 className="subheading-why">
                        Conclusion:
						</h1>
						<p className="text-small-whygnats">
                        With the Gnats NFT Project, the world of digital art takes a bold leap forward into a realm where flying creatures, vibrant colors, random paths, and natural shapes coalesce into an extraordinary visual experience. As you join the ranks of collectors, prepare to embark on a mesmerizing journey through a kaleidoscope of possibilities, where each Gnat tells its own story, and the allure of uniqueness beckons. Don't miss your chance to be part of this unparalleled NFT project that defies conventions and celebrates the boundless creativity of the digital art world.
						</p>
					</div>
				</div>	
			<div className='empty-why' /> 
            <Footer></Footer>
        </div>
    )
}