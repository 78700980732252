import { ChakraProvider, Image, Circle } from '@chakra-ui/react';
import buttonhome from './assets/buttonhome3.png';
import Footer from './footer';
import { NavLink } from 'react-router-dom';
import Gnats1 from './assets/11.gif';
import Gnats2 from './assets/20.gif';
import Gnats3 from './assets/30.gif';
import Gnats4 from './assets/40.gif';
import './mintingdetails.css';

export default function MintingDetails() {
    return (
        <div>
            <ChakraProvider>
                <section className="section-minting">
                    <div className="box-main-minting">
                        <div>
                            <Circle size='700px' bg='black'>
                                <Image src={Gnats1} boxSize='495px' />
                            </Circle>
                        </div>
                        <div className="secondhalf-minting">
                            <div className='homebutton-minting'>
                                <NavLink to='/'>
                                    <Image src={buttonhome} />
                                </NavLink>
                            </div>
                            <h1 className="text-big-minting">Minting Details</h1>
                            <p className="text-small-minting">10,000 Gnats Collection, Price: 0.08 ETH</p>
                            <p className="text-small-minting">
                                Have you seen coloured flying Gnats before? They look so good and mesmerizing, you can watch these flying all day.
                            </p>
                        </div>
                    </div>
                </section>

                <section className='section-minting'>
                    <div className='box-main-minting'>
                        <div className='secondhalf-minting'>
                            <h1 className='text-big-minting'>Gnat Traits</h1>                          
                            <p className='text-small-minting'>1. Each NFT has random number of Gnats</p>
                            <p className='text-small-minting'>2. Each NFT has random number of shapes</p>
                            <p className='text-small-minting'>3. Each NFT has "Total Flight Path" counted in pixels</p>
                            <p className='text-small-minting'>4. Each shape has random color </p>
                            <p className='text-small-minting'>5. Each Gnat has random color </p>                            
                            <p className='text-small-minting'>6. Each Gnat has random flight path </p>   
                            <p className='text-small-minting'>7. Each Gnat can enter and exit shapes </p>                             
                            <p className='text-small-minting'>8. Each Gnat is short lived </p>                                                                                  
                        </div>
                        <div>
                            <Circle size='700px' bg='black'>
                                <Image src={Gnats2} boxSize='495px' />
                            </Circle>
                        </div>
                    </div>
                </section>

                <section className="section-minting">
                    <div className="box-main-minting">
                        <div>
                            <Circle size='700px' bg='black'>
                                <Image src={Gnats3} boxSize='495px' />
                            </Circle>
                        </div>
                        <div className="secondhalf-minting">
                            <h1 className="text-big-minting">Gnats Flight Path</h1>
                            <p className="text-small-minting">
                                Gnats have a unique flight path calculated by cumulating the flight paths of all the gnats in the artwork, generating a single value. "TotalFlightPath" is the distance in pixels all gnats covered, making each NFT unique.
                            </p>
                        </div>
                    </div>
                </section>

                <section className='section-minting'>
                    <div className='box-main-minting'>
                        <div className='secondhalf-minting'>
                            <h1 className='text-big-minting'>Release Date</h1>
                            <p className='text-small-minting'>
                                Date of Release: Will be announced soon.
                            </p>
                            <p className='text-small-minting'>
                                #gnatsflyingopenly #FlyingGnats #MintGnats #GnatsInSpace #ColorfulGnats #GnatsIntheNightSky #GnatsUniverse #GnatNFT #Gnats 
                            </p>
                        </div>
                        <div>
                            <Circle size='700px' bg='black'>
                                <Image src={Gnats4} boxSize='495px' />
                            </Circle>
                        </div>
                    </div>
                </section>

                <Footer />
            </ChakraProvider>
        </div>
    );
}
