import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

//---------------RAINBOW KIT---------------------------
//import './new.css';
import '@rainbow-me/rainbowkit/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { WagmiProvider, http } from 'wagmi';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { mainnet } from 'wagmi/chains';

const projectId = '375eb9f9f5a6474fbac271fd006da1e8';
const config = getDefaultConfig({
  appName: 'Gnats',
  projectId: projectId,
  chains: [mainnet],
  transports: {
    [mainnet.id]: http('https://mainnet.infura.io/v3/375eb9f9f5a6474fbac271fd006da1e8'),
  },
})

const queryClient = new QueryClient();
const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
          <RainbowKitProvider>
              <App />
          </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
    </BrowserRouter>
  </React.StrictMode>
);
