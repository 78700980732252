
import { Routes, Route} from 'react-router-dom';
import MintingDetails  from './mintingdetails';
import Home from './home';
import About from './about';
import Roadmap from './roadmap';
import FAQ from './faq';
import WhyGnats from './whygnats';
import EventsPage from './eventspage';

function App() {
  return (
      <div className='overlay'>
        <div className="App">
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='mintingdetails' element={<MintingDetails />} />
            <Route path='about' element={<About />} />
            <Route path='roadmap' element={<Roadmap />} />
            <Route path='faq' element={<FAQ />} />
            <Route path='whygnats' element={<WhyGnats />} />
            <Route path='eventspage' element={<EventsPage />} />           
          </Routes>
        </div>
    </div>
  );
}
export default App; 


//------------------unersconstruction App------------

/*
import { Routes, Route} from 'react-router-dom';
import  Home  from './home';
function App(){
  return(
  <div className='overlay'>
    <div className='App'>
      <Routes>
        <Route path='/' element={<Home />} />
      </Routes>
    </div>
  </div>
  );
}
export default App;
*/
