import { NavLink } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import buttonhome from './assets/buttonhome3.png';
import Footer from './footer';
import './roadmap.css';

export default function Roadmap(){
    return(
        <section> 
            <div className='homebutton-roadmap'>
                <NavLink to='/'><Image src={buttonhome}></Image></NavLink>
            </div><br /><br />
            <div className="timeline-green">
                    <div className="container-green left-green">
                        <div className="content-green">
                        <h2>Phase 1: Drawing the Concept (COMPLETE)</h2><br />
                        <p>We established the creative foundation for the GNATS project. This phase focused on building the conceptual artwork and defining the project's unique visual identity. We meticulously sketched out the characteristic traits of our NFTs, including random colors, shapes, and flight paths. We also determined the random concept of Gnats and shapes in each artwork. These efforts were crucial in setting a clear and compelling artistic direction, ensuring that each GNATS piece is distinct and visually captivating. With these conceptual elements in place, we are well-prepared for the next stages of development.</p>
                        </div>
                    </div>
                    <div className="container-green right">
                        <div className="content-green">
                            <h2>Phase 2: Development and Prototyping (COMPLETE)</h2><br />
                            <p> We focused on the technical development of the GNATS project. Using JavaScript and a Node.js environment, we coded the algorithms necessary to generate 10,000 unique NFTs along with their trait files. This phase involved numerous prototypes and extensive testing to ensure the artwork met our high standards. The result is a perfected, scalable process for creating our distinctive GNATS NFTs.</p>
                        </div>    
                    </div>
                    <div className="container-green left-green">
                        <div className="content-green">
                        <h2>Phase 3: Web Portal Development and Launch (COMPLETE)</h2><br />
                        <p> We successfully developed and launched the web portal for the GNATS project. This phase spanned several months, beginning with initial concept sketches and wireframes, followed by a detailed design in Figma. The website was then built using React and Node.js, chosen for their flexibility and scalability, allowing room for future enhancements to WEB3 development. Our comprehensive approach ensures a robust and user-friendly platform for our community.</p>
                        </div>
                    </div>
                    <div className="container-green right">
                        <div className="content-green">
                            <h2>Phase 4: OpenSea Integration & Pre-Launch Setup (COMPLETE)</h2><br />
                            <p> Phase 4 was an exciting milestone where we began to see tangible results. During this phase, we set up our OpenSea profile, established our social media presence, and prepared for the pre-launch. This involved extensive work to ensure everything was perfectly aligned for our upcoming launch, creating a strong foundation for engaging with our community and showcasing our unique NFTs.</p>
                        </div>    
                    </div>
            </div>    

            <div className='timeline'>
                <div className="container left">
                    <div className="content">
                    <h2>Phase 5: Establishing a Strong Foundation</h2><br />
                    <p> Build Gnats community,
                        Actively connect with the users to earn trust, relationship and love.
                        Launch regular communication streams, such as a Discord server and a social media platform to connect with the community, share updates, and gather feedback.
                        Conduct AMA (Ask Me Anything) sessions to address community questions, provide insights into the project's development and nurture transparency.</p>
                    </div>
                   
                </div>
                <div className="container right">
                    <div className="content">
                    <h2>Phase 6: Growing and Serving the Community</h2><br />
                    <p>Engage with the Gnats NFT holders and community through regular interactive sessions. These events will allow for direct interaction, discussions and the sharing of insights.
                            Establish partnerships and collaborations with other NFT projects, artists, and influencers to expand the Gnats NFT ecosystem and reach a broader audience.
                            Implement a governance framework to allow community members to have a say in project decisions, such as future variations or additional features.</p>
                    </div>
                </div>
                <div className="container left">
                    <div className="content">
                    <h2>Phase 7: Further Variations and Expansion</h2><br />
                    <p>  Launch additional variations or editions of Gnats NFTs, introducing new features, traits, or characteristics to provide collectors with more options and increase the diversity within the collection.
                            Explore collaborations with renowned artists or creators to introduce limited edition Gnats with unique designs or artistic interpretations.
                        
                            Conduct periodic airdrops or rewards for long-term Gnats holders to foster loyalty and incentivize continued engagement with the project.</p>
                    </div>
                </div>
                <div className="container right">
                    <div className="content">
                    <h2>Enjoy!</h2><br />
                    <p>All Gnats holders and community will enjoy this phase</p>
                    </div>
                </div>
            </div>
    
        <div className='gnatsgif_roadmap' />
        <br /><br /><br /><br />
        <Footer></Footer>
    </section>
    )
}